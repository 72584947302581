import { format } from "date-fns";
import cx from "classnames";

import { capitalizeJustFirst } from "../../../utils/capitalizeJustFirst";

import classes from "./PracticeFieldDetail.module.scss";
import { priceEurFormatter } from "../../../utils/priceEuroFormatter";
import recognizeUrl from "../../../utils/recognizeUrl";

const humanizeName = (fieldKey: string) => {
	const _fieldKey = `${fieldKey}`;

	if (_fieldKey === "quotation_price") return "Prezzo preventivo";
	if (_fieldKey === "created_at") return "Creata il";
	if (_fieldKey === "updated_at") return "Modificata il";
	if (_fieldKey === "full_name") return "Nome completo";
	if (_fieldKey === "invoice_url") return "Proforma";
	if (_fieldKey === "status") return "Stato";
	if (_fieldKey === "customer_url") return "Pagina caricamento cliente";

	return capitalizeJustFirst(_fieldKey.replaceAll("_", " "));
};

const humanizeValue = (value: string, fieldKey: string) => {
	const _key = `${fieldKey}`;
	let _value = `${value}`;

	if (_key === "quotation_price") _value = priceEurFormatter(parseFloat(_value));
	if (_key === "created_at") _value = format(new Date(_value), "MM/dd/yyyy HH:mm");
	if (_key === "updated_at") _value = format(new Date(_value), "MM/dd/yyyy HH:mm");
	if (_key === "codice") return _value.toUpperCase();
	if (_key === "cf") return _value.toUpperCase();

	if (_key === "status") {
		return _value === "pending" ? "In lavorazione" : "Completata";
	}

	return capitalizeJustFirst(_value.replaceAll("_", " "));
};

const PracticeFieldDetail = ({
	className = "",
	fieldKey = "",
	fieldValue = "",
}) => {
	const _name = humanizeName(fieldKey);
	const _value = humanizeValue(fieldValue, fieldKey);

	return (
		<div className={cx(className, classes.practiceFieldDetail, { "mt-5": _name === "Prezzo preventivo" })}>
			<span className={classes.title}>{_name}:</span>
			{recognizeUrl(fieldValue)
				? <a className={classes.value} href={fieldValue} rel="noopener" target="_blank" title={_name}>Apri</a>
				: <span className={classes.value}>{_value}</span>}
		</div>
	);
};

export default PracticeFieldDetail;
