import axios from "./AxiosCaller";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const downloadFromBackend = async (downloadPath: string, fileName: string, practiceId?: any, url?: string) => {
	const { data } = await axios.get(url || `/api/documents/download/${practiceId}`, {
		responseType: "blob",
		params: {
			path: downloadPath,
		},
	});

	const _url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement("a");
	document.body.appendChild(link);
	link.href = _url;
	link.download = fileName;
	link.click();
	document.body.removeChild(link);
};

export default downloadFromBackend;
