import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import NotificationsSystem, {
	setUpNotifications, useNotifications, wyboTheme,
} from "reapop";
import { useDispatch } from "react-redux";
import { useWindowSizes } from "./hooks/useWindowSizes";

import {
	SET_IS_MOBILE, SET_IS_TABLET, SET_USER,
} from "./store/constants";

import MainLayout from "./MainLayout";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const App = () => {
	const { width } = useWindowSizes();
	const { notifications, dismissNotification } = useNotifications();
	const [loading, setLoading] = useState<boolean>(true);
	const dispatch = useDispatch();

	const fetchUser = useCallback(async () => {
		try {
			setLoading(true);

			const { data: user }: { data: User } = await axios.get("/api/user");
			dispatch({ type: SET_USER, payload: user });
		} catch (e) {
			dispatch({ type: SET_USER, payload: null });
		} finally {
			setLoading(false);
		}
	}, [dispatch]);

	useEffect(() => {
		dispatch({
			type: SET_IS_MOBILE,
			payload: width <= 767,
		});

		dispatch({
			type: SET_IS_TABLET,
			payload: width < 1024 && width > 767,
		});
	}, [dispatch, width]);

	useEffect(() => {
		setUpNotifications({
			defaultProps: {
				position: "top-right",
				dismissible: true,
				showDismissButton: true,
			},
		});
	}, []);

	useEffect(() => {
		fetchUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorBoundary>
			<NotificationsSystem
				dismissNotification={(id) => dismissNotification(id)}
				notifications={notifications}
				theme={wyboTheme}
			/>
			{!loading && <MainLayout />}
		</ErrorBoundary>
	);
};

export default App;
