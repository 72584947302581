/* eslint-disable max-lines */
import { useCallback, useEffect, useState } from "react";
import { cilTrash } from "@coreui/icons";
import {
	CButton,
	CCard, CCardBody, CCardHeader, CCol, CFormTextarea, CRow, CSpinner,
} from "@coreui/react-pro";
import { useSearchParams } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import cx from "classnames";

import { useNotifications } from "reapop";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";
import { useUploadDocument, usePractice, useDeleteDocument } from "../../../hooks/practices";
import useCheckUrlSignature from "../../../hooks/useCheckUrlSignature";
import { capitalizeJustFirst } from "../../../utils/capitalizeJustFirst";
import downloadFromBackend from "../../../utils/downloadFromBackend";
import AxiosCaller from "../../../utils/AxiosCaller";

import Page404 from "../../page404/Page404";

import classes from "./UploadDocuments.module.scss";

const UploadDocuments = () => {
	const { notify } = useNotifications();
	const [searchParams] = useSearchParams();
	const [fileError, setFileError] = useState<string>();
	const [note, setNote] = useState<string>();

	const detailsUrl = searchParams.get("details_url");
	const uploadUrl = searchParams.get("upload_url");
	const deleteUrl = searchParams.get("delete_url");
	const downloadUrl = searchParams.get("download_url");
	const noteUrl = searchParams.get("note_url") || "";

	const { checkingSignature, isValidSignature } = useCheckUrlSignature({ signedUrl: detailsUrl as string, initialFetch: true });
	const { practice, loading: practiceLoading, reFetchPractice } = usePractice({ url: detailsUrl as string });
	const { loading, errors, uploadDocument } = useUploadDocument({ url: uploadUrl as string, onUploadDone: reFetchPractice });
	const { deleteDocument } = useDeleteDocument({ url: deleteUrl as string, onDeleteDone: reFetchPractice });

	useEffect(() => {
		setNote(practice?.note || "");
	}, [practice?.note]);

	const saveNote = useCallback(async () => {
		try {
			await AxiosCaller.post(noteUrl, {
				note,
			});

			notify("Note salvate correttamente.", "success");
		} catch (e) {
			notify("Error while saving notes.", "error");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [note, notify]);

	if (!uploadUrl) {
		return (
			<Page404 />
		);
	}

	if (checkingSignature || practiceLoading) {
		return (
			<CSpinner className={classes.loader} />
		);
	}

	if (!isValidSignature) {
		return (
			<div className={classes.invalidContainer}>
				<div className={classes.invalid}>
					La sua pratica è stata completata, questa pagina non è più attiva.
					<br /><br />
					<CButton color="primary" href="https://termotecnicosmart.it">
						Torna alla home
					</CButton>
				</div>
			</div>
		);
	}

	return (
		<CCard className={classes.noBoxShadow}>
			<CCardHeader>
				Caricamento documenti pratica {!!practice && `#${practice.id}`}
			</CCardHeader>
			<CCardBody>
				<div className={classes.uploadDocuments}>
					<div className={classes.details}>
						<div className={classes.name}>
							<span className={classes.label}>Cliente:</span>
							{practice?.customer.full_name}
						</div>
						<div className={classes.codice}>
							<span className={classes.label}>Codice cliente:</span>
							{practice?.customer.codice}
						</div>
						<div className={classes.email}>
							<span className={classes.label}>Email:</span>
							{practice?.customer.email}
						</div>
						<div className={classes.intervento}>
							<span className={classes.label}>Tipo di intervento:</span>
							{capitalizeJustFirst(practice?.tipo_intervento?.replaceAll("_", " ") as string)}
						</div>
						<div className={classes.addDoc}>
							<span className={classes.label}>Aggiungi documento:</span>
							<BaseInput
								error={fileError || errors?.doc}
								inputGroupClassName="mb-3"
								loading={loading}
								maxFileSize={10240}
								setError={(error) => setFileError(error)}
								setValue={(val) => {
									uploadDocument(val[0] as File);
								}}
								type="file"
							/>
						</div>
					</div>

					<div className={classes.practice}>
						<div className={cx(classes.row, classes.header)}>
							<div className={classes.id}># Numero</div>
							<div className={classes.nome}>Nome documento</div>
							<div className={classes.download}>Scarica</div>
							<div className={classes.delete}>Elimina</div>
						</div>
						{practice?.client_documents?.map((doc, index) => (
							<div className={classes.row}>
								<div className={classes.id}>{index + 1}</div>
								<div className={classes.nome}>{doc.name}</div>
								<div className={classes.download}>
									<div
										role="button"
										tabIndex={-1}
										onClick={() => downloadFromBackend(doc.path, doc.name, undefined, downloadUrl as string)}
										onKeyPress={() => downloadFromBackend(doc.path, doc.name, undefined, downloadUrl as string)}
									>Download
									</div>
								</div>
								<div className={classes.delete}>
									<div
										role="button"
										tabIndex={-1}
										title={doc.name}
										onClick={() => deleteDocument(doc.path)}
										onKeyPress={() => deleteDocument(doc.path)}
									>
										<CIcon icon={cilTrash} />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<CRow className="mt-5">
					<CCol md={12}>
						<h4>Note</h4>
						<CFormTextarea
							className={classes.note}
							defaultValue={practice?.note || ""}
							rows={10}
							onChange={(evt) => setNote(evt.target.value)}
						/>
					</CCol>
					<CCol className="d-flex justify-content-end mt-4" md={12}>
						<CButton color="success" onClick={saveNote}>
							Salva note
						</CButton>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};

export default UploadDocuments;
