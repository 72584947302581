import React from "react";
import { CFooter } from "@coreui/react-pro";

const AppFooter = () => (
	<CFooter>
		<div>
			<a href="https://termotecnicosmart.it" rel="noopener noreferrer" target="_blank">
				termotecnicosmart.it
			</a>
			<span className="ms-1">&copy; 2022 PG Engineering srl.</span>
		</div>
		<div className="ms-auto">
			<span className="me-1">Powered by</span>
			<a href="https://mtconsultingroup.it" rel="noopener noreferrer" target="_blank">
				MT Consulting srl
			</a>
		</div>
	</CFooter>
);

export default React.memo(AppFooter);
