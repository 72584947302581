import {
	cilCloudUpload,
	// cilSettings,
	// cilPencil,
	cilPen,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
	{
		component: CNavTitle,
		name: "Legge 10",
	},
	{
		component: CNavItem,
		name: "Pratiche",
		to: "/legge10",
		icon: cilPen,
	},
	{
		component: CNavItem,
		name: "Carica documenti",
		to: "/upload",
		icon: cilCloudUpload,
	},
	// {
	// 	component: CNavTitle,
	// 	name: "Impostazioni",
	// },
	// {
	// 	component: CNavItem,
	// 	name: "Impostazioni",
	// 	to: "/settings",
	// 	icon: cilSettings,
	// },
];

export default _nav;
