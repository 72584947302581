import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react-pro";

// routes config
import routes from "../routes";

const AppContent = () => (
	<CContainer lg>
		<Suspense fallback={<CSpinner color="primary" />}>
			<Routes>
				{routes.map((route) => (
					route.element && (
						<Route
							element={<route.element />}
							exact={route.exact}
							key={route.path}
							path={route.path}
						/>
					)
				))}
				<Route element={<Navigate to="/legge10" replace />} path="/" />
			</Routes>
		</Suspense>
	</CContainer>
);

export default React.memo(AppContent);
