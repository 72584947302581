/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLInputTypeAttribute } from "react";
import CIcon from "@coreui/icons-react";
import {
	CFormInput, CInputGroup, CInputGroupText, CSpinner,
} from "@coreui/react-pro";
import { DefaultTFuncReturn } from "i18next";
import cx from "classnames";

import classes from "./BaseInput.module.scss";

type BaseInputProps = {
	type?: HTMLInputTypeAttribute;
	loading?: boolean,
	disabled?: boolean,
	multiple?: boolean,
	error?: string | string[],
	placeholder?: string | DefaultTFuncReturn;
	icon?: string | string[];
	stringIcon?: string;
	label?: string;
	inputGroup?: boolean;
	inputClassName?: string;
	inputGroupClassName?: string;
	maxFileSize?: number;
	setValue?: (value: string | File[]) => any;
	setError?: (error: string) => any;
}

export const BaseInput = ({
	type = "text",
	disabled = false,
	error = "",
	placeholder = "",
	icon,
	stringIcon,
	inputClassName = "",
	inputGroupClassName = "",
	setValue = () => null,
	inputGroup = false,
	maxFileSize = 1000000,
	multiple = false,
	setError = () => null,
	loading = false,
	label = "",
}: BaseInputProps) => {
	const _error = Array.isArray(error) ? error?.[0] : error;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onChangeValue = (evt: any) => {
		setError("");

		if (type === "file") {
			const files = evt.target.files as File[];

			for (let i = 0; i < files.length; i++) {
				if (files[i].size > (maxFileSize * 1000)) {
					setError(`La grandezza massima ammessa di un file è ${maxFileSize} kB`);
					setValue([]);
					return;
				}
			}

			setValue(files);
		} else setValue(evt.target.value as string);
	};

	if (inputGroup || !!inputGroupClassName) {
		return (
			<CInputGroup className={inputGroupClassName}>
				{icon
					&& (
						<CInputGroupText>
							<CIcon icon={icon} />
						</CInputGroupText>
					)}
				{stringIcon
					&& (
						<CInputGroupText>
							{stringIcon}
						</CInputGroupText>
					)}
				<CFormInput
					autoComplete="new-password"
					className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
					disabled={disabled || loading}
					feedbackInvalid={_error}
					invalid={!!_error}
					label={label}
					multiple={multiple}
					placeholder={placeholder as string}
					type={type}
					onChange={onChangeValue}
				/>
				{loading && <CSpinner className={classes.spinner} />}
			</CInputGroup>
		);
	}

	return (
		<>
			{icon
				&& (
					<CInputGroupText>
						<CIcon icon={icon} />
					</CInputGroupText>
				)}
			{stringIcon
				&& (
					<CInputGroupText>
						{stringIcon}
					</CInputGroupText>
				)}
			<CFormInput
				autoComplete="new-password"
				className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
				disabled={disabled || loading}
				feedbackInvalid={_error}
				invalid={!!_error}
				label={label}
				multiple={multiple}
				placeholder={placeholder as string}
				type={type}
				onChange={onChangeValue}
			/>
			{loading && <CSpinner className={classes.spinner} />}
		</>
	);
};

export default BaseInput;
