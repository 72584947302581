import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	CButtonGroup,
	CFormCheck,
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CNavLink,
	CNavItem,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
	cilMenu, cilMoon, cilSun,
} from "@coreui/icons";

import logo from "../assets/brand/logo.png";

import {
	AppHeaderDropdown,
	AppHeaderDropdownMssg,
	AppHeaderDropdownNotif,
	AppHeaderDropdownTasks,
} from "./header/index";
import AppBreadcrumb from "./AppBreadcrumb/AppBreadcrumb";
import { SET_SIDEBAR_SHOW, SET_THEME } from "../store/constants";

const AppHeader = () => {
	const dispatch = useDispatch();

	const theme = useSelector(({ base }: { base: IBase }) => base.theme);

	if (theme === "dark") document.body.classList.add("dark-theme");
	else document.body.classList.remove("dark-theme");

	const sidebarShow = useSelector(({ base }: { base: IBase }) => base.sidebarShow);

	return (
		<CHeader className="mb-4" position="sticky">
			<CContainer fluid>
				<CHeaderToggler
					className="ps-1"
					onClick={() => dispatch({ type: SET_SIDEBAR_SHOW, payload: !sidebarShow })}
				>
					<CIcon icon={cilMenu} size="lg" />
				</CHeaderToggler>
				<CHeaderBrand className="mx-auto d-md-none">
					<CIcon height={48} icon={logo} />
				</CHeaderBrand>
				<CHeaderNav className="d-none d-md-flex me-auto">
					<CNavItem>
						<CNavLink component={NavLink} to="/legge10">
							Pratiche
						</CNavLink>
					</CNavItem>
					{/* <CNavItem>
						<CNavLink to="/settings">Impostazioni</CNavLink>
					</CNavItem> */}
				</CHeaderNav>
				<CHeaderNav className="ms-3 me-4">
					<AppHeaderDropdown />
				</CHeaderNav>
			</CContainer>
			<CHeaderDivider />
			<CContainer fluid>
				<AppBreadcrumb />
			</CContainer>
		</CHeader>
	);
};

export default AppHeader;
