import React from "react";
import AdminUpload from "./pages/Auth/AdminUpload/AdminUpload";

const ListLegge10Practices = React.lazy(() => import("./pages/Auth/ListLegge10Practices/ListLegge10Practices"));
const Legge10Practice = React.lazy(() => import("./pages/Auth/Legge10Practice/Legge10Practice"));

export interface IRoute {
	path: string;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	element?: any;
	exact?: boolean;
}

const routes = [
	{
		path: "/legge10",
		name: "Legge 10",
		element: ListLegge10Practices,
		exact: true,
	},
	{ path: "/legge10/:id", name: "Dettaglio pratica", element: Legge10Practice },
	{ path: "/upload", name: "Carica documenti", element: AdminUpload },
] as IRoute[];

export default routes;
