/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	memo, useCallback, useMemo, useState,
} from "react";
import {
	CButton,
	CCard, CCardBody, CCardHeader, CCol, CRow, CSpinner,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";
import Select from "react-select";

import { useNotifications } from "reapop";
import {
	usePractice, useAllPractices, useUploadDocument, useDeleteDocument, useSendPractice,
} from "../../../hooks/practices";
import downloadFromBackend from "../../../utils/downloadFromBackend";

import PracticeFieldDetail from "../../../components/Output/PracticeFieldDetail/PracticeFieldDetail";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";

import classes from "./AdminUpload.module.scss";

const AdminUpload = () => {
	const { notify } = useNotifications();
	const [selectedId, setSelectedId] = useState<string>("");
	const [fileError, setFileError] = useState<string>("");

	const { practice, loading: practiceLoading, reFetchPractice } = usePractice({ id: selectedId });
	const { deleteDocument } = useDeleteDocument({ onDeleteDone: reFetchPractice });
	const { data: practices, loading, fetchAllPractice } = useAllPractices({
		only_pending: true,
		onFetchDone: setSelectedId,
	});

	const onSendDone = useCallback(() => {
		setSelectedId("");
		fetchAllPractice();
		notify("Pratica inviata al cliente correttamente.", "success");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAllPractice]);

	const { sendPractice, loading: sendloading } = useSendPractice({ onSendDone, id: selectedId });
	const {
		errors, loading: uploadLoading, uploadDocument,
	} = useUploadDocument({ id: selectedId, onUploadDone: reFetchPractice });

	const selectOptions = useMemo(() => practices.map((_practice) => ({
		value: _practice.id,
		label: `#${_practice.id} - ${_practice.nome} ${_practice.cognome}`,
	})), [practices]);

	return (
		<div className={classes.adminUpload}>
			<CCard>
				<CCardHeader>
					<h3>Carica documenti</h3>
				</CCardHeader>
				<CCardBody>
					<CRow>
						<CCol className="mb-4" md="12">
							<Select
								defaultValue={selectedId}
								isLoading={loading}
								options={selectOptions as any}
								placeholder="Seleziona una pratica"
								isClearable
								isSearchable
								onChange={(val: any) => setSelectedId(val?.value ?? "")}
							/>
						</CCol>
					</CRow>
					{selectedId
						&& (
							<CRow>
								<CCol md="6">
									<BaseInput
										error={fileError || errors?.doc}
										inputGroupClassName="mb-3"
										loading={uploadLoading}
										maxFileSize={10240}
										setError={(error) => setFileError(error)}
										setValue={(val) => {
											uploadDocument(val[0] as File);
										}}
										type="file"
									/>
								</CCol>
								{(practice?.admin_documents?.length || 0) > 0
									&& (
										<CCol className="d-flex align-items-center justify-content-end" md="6">
											{sendloading ? <CSpinner />
												: (
													<CButton color="success" onClick={sendPractice}>
														Invia documenti
													</CButton>
												)}
										</CCol>
									)}

								{(practice?.admin_documents?.length || 0) > 0
									&& (
										<CCol className="mt-4" md="12">
											<h4>Documenti caricati</h4>
										</CCol>
									)}

								{!practiceLoading
									? practice?.admin_documents?.map((doc) => (
										<>
											<CCol md="10">
												<PracticeFieldDetail
													fieldKey="Nome"
													fieldValue={doc.name}
													key={doc.path}
												/>
											</CCol>
											<CCol className="d-flex align-items-center justify-content-md-start" md="1">
												<div
													role="button"
													tabIndex={-1}
													title={doc.name}
													onClick={() => deleteDocument(doc.path)}
													onKeyPress={() => deleteDocument(doc.path)}
												>
													<CIcon icon={cilTrash} />
												</div>
											</CCol>
											<CCol className="d-flex align-items-center justify-content-end" md="1">
												<CButton color="warning" onClick={() => downloadFromBackend(doc.path, doc.name, practice?.id)}>
													Download
												</CButton>
											</CCol>
										</>
									))
									: <CSpinner />}

								{(practice?.client_documents?.length || 0) > 0
									&& (
										<CCol className="mt-4" md="12">
											<h4>Documenti caricati dal cliente</h4>
										</CCol>
									)}

								{practice?.client_documents?.map((doc) => (
									<>
										<CCol md="10">
											<PracticeFieldDetail
												fieldKey="Nome"
												fieldValue={doc.name}
												key={doc.path}
											/>
										</CCol>
										<CCol className="d-flex align-items-center justify-content-end" md="2">
											<CButton color="warning" onClick={() => downloadFromBackend(doc.path, doc.name, practice?.id)}>
												Download
											</CButton>
										</CCol>
									</>
								))}
							</CRow>
						)}
				</CCardBody>
			</CCard>
		</div>
	);
};

export default memo(AdminUpload);
