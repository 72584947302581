/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import AxiosCaller from "../utils/AxiosCaller";

export interface ITablePractice {
	id: number;
	codice: string;
	created_at: string;
	email: string;
	nome: string;
	cognome: string;
	price: number;
	status: string;
	full_name?: string;
	ragione_sociale: string;
}

export const useAllPractices = ({
	page = 1,
	search = "",
	per_page = 20,
	sort_by = "created_at",
	sort_direction = "desc",
	only_pending = false,
	onFetchDone = null as any,
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [from, setFrom] = useState<number>(0);
	const [to, setTo] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const [last_page, setLastPage] = useState<number>(1);
	const [current_page, setCurrentPage] = useState<number>(1);
	const [data, setData] = useState<ITablePractice[]>([]);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchAllPractice = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);

			if (only_pending) {
				const { data: _data }: { data: ITablePractice[] } = await AxiosCaller.get("/api/legge10/pending/practices");
				setData(_data);
			} else {
				const {
					data: {
						from: _from,
						to: _to,
						total: _total,
						last_page: _last_page,
						current_page: _current_page,
						data: _data,
					},
				}: {
					data: {
						from: number,
						to: number,
						total: number,
						last_page: number,
						current_page: number,
						data: ITablePractice[],
					}
				} = await AxiosCaller.get("/api/legge10", {
					params: {
						page,
						search,
						per_page,
						sort_by,
						sort_direction,
					},
				});

				setFrom(_from);
				setTo(_to);
				setFrom(_from);
				setTotal(_total);
				setLastPage(_last_page);
				setCurrentPage(_current_page);
				setData(_data);
			}
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
			if (onFetchDone) onFetchDone();
		}

		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [only_pending, page, per_page, search, sort_by, sort_direction]);

	useEffect(() => {
		fetchAllPractice();
	}, [fetchAllPractice]);

	return {
		fetchAllPractice,
		from,
		to,
		total,
		last_page,
		current_page,
		data,
		loading,
		errors,
	};
};

export const usePractice = ({
	id,
	url,
}: { id?: number | string; url?: string }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [practice, setPractice] = useState<Practice | null>(null);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchPractice = useCallback(async () => {
		if (!url && !id) return;

		try {
			setLoading(true);
			setErrors(null);

			const { data }: { data: Practice } = await AxiosCaller.get(!url ? `/api/legge10/${id}` : url);

			setPractice(data);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [id, url]);

	useEffect(() => {
		fetchPractice();
	}, [fetchPractice]);

	return {
		reFetchPractice: fetchPractice,
		practice,
		loading,
		errors,
	};
};

export const useUploadDocument = ({
	id,
	url,
	onUploadDone,
}: { url?: string; id?: number | string; onUploadDone?: any }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);
	const [message, setMessage] = useState<string | null>();

	const uploadDocument = useCallback(async (file: File) => {
		try {
			setLoading(true);
			setErrors(null);
			setMessage(null);

			const { data: { message: _message } }: { data: { message: string } } = await AxiosCaller.post(
				url || "/api/legge10/upload",
				{
					doc: file,
					practice_id: id,
				},
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
			);

			setMessage(_message);
			if (onUploadDone) onUploadDone();
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [id, onUploadDone, url]);

	return {
		message,
		loading,
		errors,
		uploadDocument,
	};
};

export const useDeleteDocument = ({
	url,
	onDeleteDone,
}: { url?: string; onDeleteDone?: any }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);
	const [message, setMessage] = useState<string | null>();

	const deleteDocument = useCallback(async (path: string) => {
		try {
			setLoading(true);
			setErrors(null);
			setMessage(null);

			const { data: { message: _message } }: { data: { message: string } } = await AxiosCaller.delete(
				url || "/api/legge10/upload",
				{
					params: {
						path,
					},
				},
			);

			setMessage(_message);
			if (onDeleteDone) onDeleteDone();
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [onDeleteDone, url]);

	return {
		message,
		loading,
		errors,
		deleteDocument,
	};
};

export const useSendPractice = ({
	id = "",
	onSendDone = null as any,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);
	const [message, setMessage] = useState<string | null>();

	const sendPractice = useCallback(async () => {
		if (!id) return;

		try {
			setLoading(true);
			setErrors(null);
			setMessage(null);

			const { data: { message: _message } }: { data: { message: string } } = await AxiosCaller.post(
				"/api/legge10/send",
				{
					practice_id: id,
				},
			);

			setMessage(_message);
			if (onSendDone) onSendDone();
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [onSendDone, id]);

	return {
		message,
		loading,
		errors,
		sendPractice,
	};
};
